import React from "react";

import { schema } from "rdf-namespaces";
import { useEffect, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import Input from "../Profile/Form/Input/Input";

export default function Bio({ bio, names, editableDatasets }) {
  const [editMode, setEditMode] = useState(false);

  const handleToggleEditMode = () => {
    if (!editableDatasets)
      throw new Error("You don't have any editable datasets");
    setEditMode(!editMode);
  };

  return (
    <div id="avatar-bio-wrapper">
      <span id="name-avatar-wrapper">
        <h1>{names[0].value} </h1>
        <Avatar variant={"profile"} />
      </span>
      <span id="bio-wrapper">
        <h2>Bio</h2>
        {bio?.value &&
          (!editMode ? (
            <span id="bio">{bio.value}</span>
          ) : (
            <Input
              editableDatasets={[bio?.dataset] ?? editableDatasets}
              field={{
                property: schema.description,
                name: "bios",
                dataset: editableDatasets[0] || null,
                type: "string",
              }}
            />
          ))}
        <button onClick={handleToggleEditMode} className="primary-button">Edit</button>
      </span>
    </div>
  );
}

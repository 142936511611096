import { getProfileAll, getThing, getUrl } from "@inrupt/solid-client";
import { solid } from "rdf-namespaces";
import { idpMatchers, idps } from "../constants/idp";

export async function getOidcIssuer(webId) {
    const profile = await getProfileAll(webId);
    const { webIdProfile } = profile;
    const thing = getThing(webIdProfile, webId)
    const oidcIssuer = getUrl(thing, solid.oidcIssuer);
    return oidcIssuer;
}

export function deriveIdpFromWebId(webId) {
    let idp;
    idpMatchers.forEach((matcher, index) => {
        if (webId.includes(matcher)) {
            idp = idps[index];
        }
    })
    return idp;
}
import React from "react";
import { getSourceUrl, getThing, saveSolidDatasetAt, setThing } from "@inrupt/solid-client";
import useSession from "../../../../hooks/useSession";
import { useEffect, useState } from "react";
import { methodMap, propertyMap, typeMap } from "../../../../utils/helpers/profile";

export default function Input({ field, editableDatasets }) {
    const { session } = useSession();
    const [value, setValue] = useState(field.value || "");
    const [type, setType] = useState(field.type);
    const [isEditing, setIsEditing] = useState(false);
    const [property, setProperty] = useState(field.property || propertyMap[field.name]);
    // TODO: let user pick a dataset if more than one
    const [dataset, setDataset] = useState(field.dataset || null);
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (!editableDatasets) return;
        setDataset(editableDatasets[0])
    }, [editableDatasets])

    const handleSaveField = async (e, mode) => {
        if (mode !== "save") return;
        setSaving(true);
        const thing = getThing(dataset ?? editableDatasets[0], session.info.webId)

        const { setDataItem, addDataItem } = type ? methodMap[type] : methodMap[typeMap[field.name]];

        if (!field.value) {
            // create a new dataset and link it to webID if editable, or return an error saying user cannot edit any of their datasets (unlikely)
            // throw new Error("You don't have any editable datasets")
            console.log(thing, property, value)
            const modifiedThing = addDataItem(thing, property, value)
            const updatedDataset = setThing(editableDatasets[0], modifiedThing);
            const modifiedDataset = await saveSolidDatasetAt(getSourceUrl(editableDatasets[0]), updatedDataset, { fetch: session.fetch });
            setDataset(modifiedDataset);
            setSaving(false);
        } else {
            const modifiedThing = setDataItem(thing, property, value)
            const updatedDataset = setThing(dataset, modifiedThing)
            const modifiedDataset = await saveSolidDatasetAt(getSourceUrl(dataset), updatedDataset, { fetch: session.fetch });
            setDataset(modifiedDataset);
            setSaving(false);
        }
    };

    const handleInputChange = (e) => {
        setValue(e.target.value)
    }

    const toggleEdit = (e, mode) => {
        handleSaveField(e, mode);
        setIsEditing(!isEditing);
    }

    const renderIcon = (mode) => {
        if (saving) {
            // TODO: replace with a spinner
            return <span>saving...</span>
        }
        if (isEditing) {
            return '💾'
        } else {
            if (mode === "edit") {
                return '✍'
            } else {
                return '✚'
            }
        }

    }

    const renderButton = (value) => {
        if (value) {
            //TODO: change this class name
            return (<button className="counter-button" onClick={(e) => toggleEdit(e, isEditing ? "save" : "edit")}>{renderIcon(isEditing ? "save" : "edit")}</button>)
        } else {
            return <button className="counter-button" onClick={(e) => toggleEdit(e, isEditing ? "save" : "edit")}>{renderIcon()}</button>
        }
    }

    useEffect(() => {
        setValue(field.value || "")
        setProperty(field.property || propertyMap[field.name])
        setType(field.type)
    }, [field])

    return (
        <>
            <input type="text" property={field.property} value={value} onChange={handleInputChange} disabled={!isEditing}/>
            {renderButton(value)}
        </>
    )
}
import { getFile } from "@inrupt/solid-client";
import { useState, useEffect } from "react";
import useSession from "./useSession";

export default function useFile(url) {
    const { session } = useSession();
    const [file, setFile] = useState(null);
    useEffect(() => {
        if (!url) return;
        (async () => {
            try {
                const file = await getFile(url, { fetch: session.fetch });
                setFile(file);
            } catch (e) {
                // ignore errors
            }
        })()
    }, [url, session])
    return file;
}
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "../../Avatar/Avatar";
import "./UserMenu.css";

export default function UserMenu({ session, avatar }) {
  return (
    <Dropdown className="user-menu-dropdown">
      <Dropdown.Toggle>
        <Avatar variant="menu" avatar={avatar} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-2">
          <Link to="/login">Log in</Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

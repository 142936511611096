import { useContext } from "react";
import { SessionContext } from "../context/SessionContext";

export default function useSession() {
  const { session, sessionRequestInProgress, fetch, login, logout } =
    useContext(SessionContext);

  return {
    session,
    sessionRequestInProgress,
    fetch,
    login,
    logout,
  };
}
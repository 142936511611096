import React, {
    createContext,
    ReactElement,
    useState,
    SetStateAction,
    Dispatch,
    useEffect,
    ReactNode,
  } from "react";
  
  import {
    fetch,
    login,
    logout,
    handleIncomingRedirect,
    Session,
    getDefaultSession,
    onSessionRestore as onSessionRestoreClient,
  } from "@inrupt/solid-client-authn-browser";
  
  import {
    SolidDataset,
    getProfileAll,
    ProfileAll,
    WithServerResourceInfo,
  } from "@inrupt/solid-client";

  
  export const SessionContext = createContext({
    login,
    logout,
    fetch,
    session: getDefaultSession(),
    sessionRequestInProgress: true,
    profile: undefined,
  });


  export const SessionProvider = ({
    sessionId,
    children,
    onError,
    sessionRequestInProgress: defaultSessionRequestInProgress,
    restorePreviousSession,
    skipLoadingProfile,
    onSessionRestore,
  }) => {
    const restoreSession =
      restorePreviousSession || typeof onSessionRestore !== "undefined";
    const [session, setSession] = useState(getDefaultSession());
    const [profile, setProfile] =
      useState();
  
    useEffect(() => {
      if (onSessionRestore !== undefined) {
        onSessionRestoreClient(onSessionRestore);
      }
    }, [onSessionRestore]);
  
    const defaultInProgress =
      typeof defaultSessionRequestInProgress === "undefined"
        ? !session.info.isLoggedIn
        : defaultSessionRequestInProgress;
  
    // If loggedin is true, we're not making a session request.
    const [sessionRequestInProgress, setSessionRequestInProgress] =
      useState(defaultInProgress);
  
    let currentLocation;
  
    if (typeof window !== "undefined") {
      currentLocation = window.location;
    }
    useEffect(() => {
      handleIncomingRedirect({
        url: window.location.href,
        restorePreviousSession: restoreSession,
      })
        .then(async (sessionInfo) => {
          if (skipLoadingProfile === true) {
            return;
          }
  
          // If handleIncomingRedirect logged the session in, we know what the current
          // user's WebID is.
          if (sessionInfo?.webId !== undefined) {
            const profiles = await getProfileAll(sessionInfo?.webId, {
              fetch: session.fetch,
            });
  
            setProfile(profiles);
          }
        })
        .catch((error) => {
          if (onError) {
            onError(error);
          } else {
            throw error;
          }
        })
        .finally(() => {
          setSessionRequestInProgress(false);
        });
  
      getDefaultSession().on("logout", () => {
        setSession(getDefaultSession());
      });
    }, [
      session,
      sessionId,
      onError,
      currentLocation,
      restoreSession,
      skipLoadingProfile,
    ]);
  
    const contextLogin = async (options) => {
      setSessionRequestInProgress(true);
  
      try {
        await login(options);
      } catch (error) {
        if (onError) {
          onError(error);
        } else {
          throw error;
        }
      } finally {
        setSessionRequestInProgress(false);
      }
    };
  
    const contextLogout = async () => {
      try {
        await logout();
        setProfile(undefined);
      } catch (error) {
        if (onError) {
          onError(error);
        } else {
          throw error;
        }
      }
    };
  
    return (
      <SessionContext.Provider
        value={{
          session,
          login: contextLogin,
          logout: contextLogout,
          sessionRequestInProgress,
          setSessionRequestInProgress,
          fetch,
          profile,
        }}
      >
        {children}
      </SessionContext.Provider>
    );
  };
import React from "react";
import Spinner from "../../components/Spinner/Spinner";
import useProfile from "../../hooks/useProfile";
import { HashLink as Link } from "react-router-hash-link";
import { useState } from "react";
import Friends from "../Friends/Friends";
import About from "../About/About";
// import Photos from "../Photos/Photos";
import Bio from "../Bio/Bio";
import useSession from "../../hooks/useSession";
import LoginForm from "../Login/LoginForm";
import HomePage from "../Homepage/HomePage";

const sections = ["activity", "photos", "friends", "about", "contact"];
const about = ["names", "roles", "organizations"];

export default function Dashboard({ webId }) {
  const { session } = useSession();
console.log(session.info)
  const { profile, error } = useProfile(webId ?? session.info.webId);
  const [selectedTab, setSelectedTab] = useState(window.location.hash.length ? window.location.hash : "activity");

  if (error && error.statusCode === 401 && !session.info.isLoggedIn) {
    return (<LoginForm/>)
  }

  if (error && error.statusCode === 401 && session.info.isLoggedIn) {
    // TODO: request access button
    return (<div>You don't have access to that </div>)
  }
  const aboutFields =
    profile &&
    Object.entries(profile).filter((item) => about.includes(item[0]));


  if (!session.info.isLoggedIn) {
    return <HomePage/>
  }
  if (!profile) return <Spinner />;
  return (
    <div id="wrapper">
      <div id="content">
        <Bio
          names={profile?.names}
          bio={profile?.bios[0]}
          editableDatasets={profile?.editableProfileDatasets}
        />
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

        {/* /* TODO: each section is a component */}
        {sections.map((s) => {
          if (s === "friends")
            return (
              <Friends
                isHidden={selectedTab !== "friends"}
                friends={profile?.friends}
                editableDatasets={profile?.editableProfileDatasets}
              />
            );
          if (s === "about")
            return (
              <About
                isHidden={selectedTab !== "about"}
                about={aboutFields}
                editableDatasets={profile?.editableProfileDatasets}
              />
            );
          // if (s === "photos")
          //   return (
          //     <Photos
          //       isHidden={selectedTab !== "photos"}
          //       pods={profile?.pods}
          //       photosContainerUrl={`${profile?.pods[0].value}photos/`}
          //       editableDataset={profile?.editableProfileDatasets[0].value}
          //     />
          //   );
          return (
            <section id={s} hidden={selectedTab !== s}>
              {s} list
            </section>
          );
        })}
      </div>
    </div>
  );
}

function Tabs({ selectedTab, setSelectedTab }) {
  return (
    <nav id="tabs">
      <ul>
        {sections.map((label) => (
          <li className={selectedTab === label ? "selected-tab" : null} key={label} onClick={() => setSelectedTab(label)}>
            <Link to={`#${label}`}>{label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function HomePage() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>
            {/* TODO: update text */}
            <i>self</i> is a profile viewer and editor for individuals and
            collective social entities to present and manage their online
            identities.
          </h1>
        </Col>
      </Row>
    </Container>
  );
}

import React from "react";
import useSession from "../../hooks/useSession";
import { vcard } from "rdf-namespaces";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useProfile from "../../hooks/useProfile";
import { saveFile } from "../../utils/helpers/file";
import { getRandomAvatar } from "../../utils/helpers/profile";
import Toggles from "../Toggles/Toggles";
import AvatarInput from "./AvatarInput/AvatarInput";
import Photo from "./Photo/Photo";

function RandomAvatar({ variant }) {
  return (
    <>
      <span className={`default-avatar-${variant}`}>{getRandomAvatar()}</span>
    </>
  );
}

export default function Avatar({ variant, webId, avatar }) {
  const { session } = useSession();
  const { profile } = useProfile(webId ?? session.info.webId);
  const [dataset, setDataset] = useState(
    profile?.editableProfileDatasets ? profile.editableProfileDatasets[0] : null
  );
  const [availableAvatars, setAvailableAvatars] = useState(profile?.avatars);
  const [currentAvatarIndex, setCurrentAvatarIndex] = useState(0);
  const [addingPhoto, setAddingPhoto] = useState(false);
  const [toggleText, setToggleText] = useState("Add New Photo");

  async function handleSaveFile() {
    const selectedFile = document.getElementById("avatar").files[0];
    const savedDataset = await saveFile(
      selectedFile,
      dataset,
      vcard.hasPhoto,
      session
    );
    setDataset(savedDataset);
  }

  const toggleAddPhoto = () => {
    setAddingPhoto(!addingPhoto);
  };

  useEffect(() => {
    if (!profile || !profile.editableProfileDatasets) return;
    setDataset(profile.editableProfileDatasets[0]);
  }, [profile]);

  useEffect(() => {
    if (!profile) return;
    setAvailableAvatars(profile.avatars);
  }, [availableAvatars, profile]);

  useEffect(() => {
    if (addingPhoto) {
      setToggleText("Cancel");
    } else {
      setToggleText("Add New Photo");
    }
  }, [addingPhoto]);

  const renderMenuComponent = () => {
    if (!session.info.isLoggedIn) {
      return <>{avatar}</>;
    }

    if (availableAvatars?.length) {
      return (
        <div id="avatar-container">
          <button
            onClick={() => console.log("menu")}
            className="user-menu-button"
          >
            <Photo
              iri={availableAvatars[currentAvatarIndex]}
              alt={`${profile?.names[0].value || profile.webId}'s avatar`}
              testId={`avatar-${currentAvatarIndex}`}
              variant={variant}
            />
          </button>
        </div>
      );
    }

    if (!availableAvatars?.length) {
      return <RandomAvatar variant={variant} />;
    }
  };

  const renderProfileComponent = () => {
    // no avatars and no dataset
    if (!dataset && !availableAvatars?.length) {
      return <RandomAvatar variant={variant} />;
    }
    // no avatars and dataset
    if (dataset && !availableAvatars?.length) {
      return (
        <>
          <RandomAvatar variant={variant} />
          <button onClick={toggleAddPhoto} className="primary-button">
            {toggleText}
          </button>
          {addingPhoto && (
            <AvatarInput variant={variant} clickHandler={handleSaveFile} />
          )}
        </>
      );
    }
    // avatars and dataset
    if (dataset && availableAvatars.length) {
      return (
        <div id="avatar-container">
          <button onClick={toggleAddPhoto} className="primary-button">
            {toggleText}
          </button>
          <Toggles
            currentIndex={currentAvatarIndex}
            setCurrentIndex={setCurrentAvatarIndex}
            length={availableAvatars?.length}
          >
            <Photo
              iri={availableAvatars[currentAvatarIndex]}
              alt={`${profile?.names[0].value || profile.webId}'s avatar`}
              testId={`avatar-${currentAvatarIndex}`}
              variant={variant}
            />
          </Toggles>

          {addingPhoto && <AvatarInput clickHandler={handleSaveFile} />}
        </div>
      );
    }
    // avatars and no dataset
    if (!dataset && availableAvatars.length) {
      return (
        <>
          <Toggles
            currentIndex={currentAvatarIndex}
            setCurrentIndex={setCurrentAvatarIndex}
            length={availableAvatars?.length}
          >
            <Photo
              iri={availableAvatars[currentAvatarIndex]}
              alt={`${profile?.names[0].value}'s avatar`}
              testId={`avatar-${currentAvatarIndex}`}
              variant={variant}
            />
          </Toggles>
        </>
      );
    }
  };

  return (
    <>{variant === "menu" ? renderMenuComponent() : renderProfileComponent()}</>
  );
}

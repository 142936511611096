import React from "react";
import { getRandomAvatar } from "../../utils/helpers/profile";
import UserMenu from "./UserMenu/UserMenu";
import "./Header.css";

export default function Header({ session }) {
    const avatar = getRandomAvatar();
    return (
        <header>
            <UserMenu session={session} avatar={avatar}/>
        </header>
    )
}
import React from "react";
import { SessionContext } from "../../context/SessionContext";
import { useContext, useMemo } from "react";
import LoginForm from "../Login/LoginForm";
import Header from "../../components/Header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Dashboard from "../Dashboard/Dashboard";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }

export default function MainPage() {
  const { session, sessionRequestInProgress } = useContext(SessionContext);

  const query = useQuery();
  if (sessionRequestInProgress) return <Spinner />;


  // if webId in query params, show profile
  
  // if no webId in params but user logged in, show user's own profile

  // if neither, show landing page (TBD)

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <>
            <LoginForm />
          </>
        }
      />
      <Route index element={
        (
            <>
            {/* FIXME: session provider */}
            <Header session={session} />
            <Dashboard session={session} webId={query.get("webId") || session.info.webId}/>
          </>
        )
      }/>
      <Route
        index
        element={
          session.info.isLoggedIn ? (
            <>
              <Header session={session} />
              <Dashboard webId={session.info.webId} />
            </>
          ) : (
            <>
              <Header session={session} />
              <LoginForm />
            </>
          )
        }
      />
    </Routes>
  );
}

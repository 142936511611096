import {
  addStringNoLocale,
  addUrl,
  getEffectiveAccess,
  getSolidDataset,
  getStringNoLocale,
  getThing,
  getUrl,
  getUrlAll,
  setStringNoLocale,
  setUrl,
} from "@inrupt/solid-client";
import { foaf, rdf, rdfs, schema, space, vcard } from "rdf-namespaces";

export const methodMap = {
  string: {
    getDataItem: getStringNoLocale,
    setDataItem: setStringNoLocale,
    addDataItem: addStringNoLocale,
  },
  url: {
    getDataItem: getUrl,
    setDataItem: setUrl,
    addDataItem: addUrl,
  },
};

export const typeMap = {
  names: "string",
  roles: "string",
  organizations: "string",
  bios: "string",
  friends: "url",
};

// this is terrible
export const propertyMap = {
  names: foaf.name,
  roles: vcard.role,
  organizations: "http://www.w3.org/2006/vcard/ns#organization-name",
  bios: schema.description,
  friends: foaf.knows,
};

export const profileDataItems = [
  {
    label: "names",
    getDataItem: getStringNoLocale,
    properties: [foaf.name, vcard.fn],
    type: "string",
  },
  {
    label: "avatars",
    getDataItem: getUrlAll,
    properties: [vcard.hasPhoto, foaf.img, schema.image, foaf.depiction],
    type: "url",
  },
  {
    label: "types",
    getDataItem: getUrl,
    properties: [rdf.type],
    type: "url",
  },
  {
    label: "roles",
    getDataItem: getStringNoLocale,
    properties: [vcard.role],
    type: "string",
  },
  {
    label: "organizations",
    getDataItem: getStringNoLocale,
    properties: ["http://www.w3.org/2006/vcard/ns#organization-name"],
    type: "string",
  },
  {
    label: "pods",
    getDataItem: getUrl,
    properties: [space.storage],
    type: "url",
  },
  {
    label: "bios",
    getDataItem: getStringNoLocale,
    properties: [schema.description],
    type: "string",
  },
  {
    label: "friends",
    getDataItem: getUrlAll,
    properties: [foaf.knows],
    type: "url",
  },
];

export const contactInfoDataItems = [
  {
    label: "phones",
    properties: [vcard.hasTelephone],
  },
  {
    label: "emails",
    properties: [vcard.hasEmail],
  },
];

export async function getFullProfile(webId, session) {
  const profile = {
    names: [],
    avatars: [],
    types: [],
    webId,
    roles: [],
    pods: [],
    bios: [],
    friends: [],
    organizations: [],
    editableProfileDatasets: [],
  };
  const readableProfileDocuments = [];
  let webIdProfileDataset;

  // fetch WebID, if fails return. if succeeds, add to readable docs.
  try {
    webIdProfileDataset = await getSolidDataset(webId, {
      fetch: session.fetch,
    });
    readableProfileDocuments.push(webIdProfileDataset);
  } catch (e) {
    return profile;
  }
  // get SeeAlsos in preferences file and webID
  const webIdProfileThing = getThing(webIdProfileDataset, webId);
  const preferencesFileUrl = getUrl(webIdProfileThing, space.preferencesFile);

  const extendedProfilesUrls = getUrlAll(webIdProfileThing, rdfs.seeAlso);
  extendedProfilesUrls.push(
    ...getUrlAll(webIdProfileThing, "http://xmlns.com/foaf/0.1/isPrimaryTopicOf")
  );

  if (preferencesFileUrl && webId === session.info.webId) {
    try {
      const preferencesFile = await getSolidDataset(preferencesFileUrl, {
        fetch: session.fetch,
      });
      const preferencesFileThing = getThing(preferencesFile, webId);
      const seeAlsoUrls = getUrlAll(preferencesFileThing, rdfs.seeAlso);
      extendedProfilesUrls.push(...seeAlsoUrls);
    } catch (e) {
      // ignore, if we cannot get this we move on
      console.log(e)
    }
  }

  const extendedProfileDocuments = await Promise.all(
    extendedProfilesUrls.map((url) => {
      return url && getSolidDataset(url, { fetch: session.fetch });
    })
  );

  readableProfileDocuments.push(...extendedProfileDocuments);
  // try to find profile data all  the readable documents

  readableProfileDocuments.forEach((doc) => {
    if (!doc) return;
    const { user: profileEditingAccess } = getEffectiveAccess(doc);
    const isProfileEditable =
      profileEditingAccess.write || profileEditingAccess.append;
    if (isProfileEditable) {
      profile.editableProfileDatasets.push(doc);
    }
    const webIdSubjectThing = getThing(doc, webId);

    profileDataItems.forEach((dataItem) => {
      dataItem.properties.forEach((property) => {
        const value = dataItem.getDataItem(webIdSubjectThing, property);
        if (value) {
          if (Array.isArray(value)) {
            value.forEach((val) => {
              profile[dataItem.label].push({
                value: val,
                property,
                type: dataItem.type,
                dataset: isProfileEditable ? doc : null,
              });
            });
          } else {
            profile[dataItem.label].push({
              value,
              property,
              type: dataItem.type,
              dataset: isProfileEditable ? doc : null,
            });
          }
        }
      });
    });
  });

  // TODO: contact info
  return profile;
}

export const defaultAvatars = ["🦁", "🐵", "🦝", "🐹", "🐼", "🐰"];

export const getRandomAvatar = () => {
  const index = Math.floor(Math.random() * defaultAvatars.length);

  return defaultAvatars[index];
};

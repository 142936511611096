import React from "react";

export default function Toggles({ currentIndex, setCurrentIndex, length, children }) {

    const addCounter = () => {
        setCurrentIndex(currentIndex + 1)
    }

    const substractCounter = () => {
        setCurrentIndex(currentIndex - 1)
    }

    return (
        <span className="avatar-toggles-container">
            {currentIndex > "0" && <button onClick={substractCounter} className="counter-button"> &#9194; </button>}
            {children}
            {currentIndex < (length - 1) && <button onClick={addCounter} className="counter-button"> &#9193; </button>}
        </span>
    )

}
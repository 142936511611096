import { addUrl, getSourceIri, getSourceUrl, getThing, saveFileInContainer, saveSolidDatasetAt, setThing } from "@inrupt/solid-client";

    export const saveFile = async (selectedFile, dataset, property, session) => {
        // save the file in dataset root, get its url
        const datasetUrl = getSourceUrl(dataset);
        const root = datasetUrl.substring(0, datasetUrl.lastIndexOf("/") + 1);
        const uploadedFile = await saveFileInContainer(root, selectedFile, { fetch: session.fetch });

        // add the url in the thing, set thing in dataset, save dataset
        const fileUrl = getSourceIri(uploadedFile);
        //TODO: handle if there's no triples with webid as subject
        const thing = getThing(dataset, session.info.webId);
        const modifiedThing = addUrl(thing, property, fileUrl);
        const modifiedDataset = setThing(dataset, modifiedThing);
        const savedDataset = await saveSolidDatasetAt(datasetUrl, modifiedDataset, { fetch: session.fetch })
        return savedDataset;
    }
import React from "react";
import { mockSolidDatasetFrom } from "@inrupt/solid-client"
import { foaf, vcard } from "rdf-namespaces"
import { useState } from "react"
import { propertyMap, typeMap } from "../../utils/helpers/profile";
import Input from "../Profile/Form/Input/Input";

export default function About({ isHidden, about, editableDatasets }) {
    const [editMode, setEditMode] = useState(false);

    const handleToggleEditMode = () => {
        if (!editableDatasets) throw new Error("You don't have any editable datasets")
        setEditMode(!editMode);
    }

    return (
        <section id="about" hidden={isHidden}>
            <h1>About</h1>
            <button onClick={handleToggleEditMode}>Edit</button>
            <ul>
                {about?.map((s, i) => {
                    console.log(s)
                    return (
                        <>
                            <h2>{s[0]}</h2>
                            <ul>
                                {
                                    s[1].length ? s[1].map((f) => (
                                        <li>{editMode ? <Input field={f} editableDatasets={[f.dataset]} /> : f.value}</li>
                                    ))
                                        :
                                        (
                                            <li>{editMode ? <Input field={{
                                                name: s[0],
                                                type: typeMap[s[0]],
                                                property: propertyMap[s[0]],
                                                dataset: editableDatasets[0]
                                            }} editableDatasets={editableDatasets} /> : ""}</li>
                                        )
                                }
                            </ul>
                        </>
                    )
                })}
            </ul>
        </section>
    )

}
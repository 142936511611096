import React from "react";
import { useEffect, useState } from "react";
import useFile from "../../../hooks/useFile";

export default function Photo({ iri, alt, testId, variant }) {
    const [fetchedPhotoUrl, setFetchedPhotoUrl] = useState(null);
    const file = useFile(iri);

    useEffect(() => {
        if (!file) return;
        setFetchedPhotoUrl(URL.createObjectURL(file));
    }, [file])

    return (
        <img src={fetchedPhotoUrl} data-testid={testId} alt={alt} className={`avatar avatar-${variant}`} />
    )
}
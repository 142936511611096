export default function AvatarInput({ clickHandler }) {

    return (<span>
        <button onClick={clickHandler} className="primary-button">Upload</button>
        <label className="primary-button">
            Choose File
            <input type="file"
                id="avatar" name="avatar"
                accept="image/png, image/jpeg"
            />
        </label>
    </span>)
}
import { useEffect, useState } from "react";
import { getFullProfile } from "../utils/helpers/profile";
import useSession from "./useSession";

export default function useProfile(webId) {
    const { session } = useSession();
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
console.log(error)
    useEffect(() => {
        (async () => {
            try {
                const profile = await getFullProfile(webId, session);
                setProfile(profile);
                setError(null);
            } catch (e) {
                setProfile(null);
                setError(e);
            }

        })()
    }, [webId, session])

    return { profile, error };
}
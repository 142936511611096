import React from "react";
import useProfile from "../../hooks/useProfile";
import Avatar from "../../components/Avatar/Avatar";
import { useState } from "react";
import {
  addUrl,
  getSourceUrl,
  getThing,
  saveSolidDatasetAt,
  setThing,
  setUrl,
} from "@inrupt/solid-client";
import useSession from "../../hooks/useSession";
import { foaf } from "rdf-namespaces";

export default function Friends({ isHidden, friends, editableDatasets }) {
  const { session } = useSession();
  const [open, setOpen] = useState(false);
  const [webId, setWebId] = useState("");

  const toggleAddFriendForm = () => {
    setOpen(true);
  };

  async function addFriend(e) {
    e.preventDefault();
    // get thing from dataset
    const thing = getThing(editableDatasets[0], session.info.webId); // first one for now
    // add foaf:knows to thing
    const updatedThing = addUrl(thing, foaf.knows, webId);
    // set thing in dataset
    const updatedDataset = setThing(editableDatasets[0], updatedThing);
    // save dataset
    await saveSolidDatasetAt(getSourceUrl(editableDatasets[0]), updatedDataset, { fetch: session.fetch});
    // TODO: trigger a refresh
  }

  return (
      <section id="friends" hidden={isHidden}>
        <ul rel="foaf:knows">{friends?.map((friend) => Friend(friend))}</ul>
        <button onClick={toggleAddFriendForm}>Add Friend</button>
      {open && (
        <div>
          <form onSubmit={addFriend}>
            <input
              type="text"
              value={webId}
              onChange={(e) => setWebId(e.target.value)}
            ></input>
            <button type="submit">Add</button>
          </form>
        </div>
      )}
      </section>
  );
}

function Friend(friend) {
  const { profile: friendProfile } = useProfile(friend.value);
  return (
    <li>
      <a href={friend.value}>
        <Avatar webId={friend.value} variant="menu" />
        {friendProfile && friendProfile.names.length
          ? friendProfile.names[0]
          : friend.value}
      </a>
    </li>
  );
}

import React from "react";
import { useCallback, useState } from "react";
import { deriveIdpFromWebId, getOidcIssuer } from "../../utils/helpers/auth";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "./LoginForm.css";
import solid_icon from "../../assets/solid_icon.png";
import podspaces_logo from "../../assets/podspaces_logo.png";
import useSession from "../../hooks/useSession";

export default function LoginForm() {
  const { login: sessionLogin } = useSession();
  const [inputWebId, setInputWebId] = useState("");
  const [inputIdp, setInputIdp] = useState("");
  const [isIdpModalOpen, setIsIdpModalOpen] = useState(false);

  const showIdpModal = () => {
    setIsIdpModalOpen(true);
  };

  const hideIdpModal = () => {
    setIsIdpModalOpen(false);
  };

  const login = useCallback(
    (oidcIssuer) => {
      sessionLogin({
        oidcIssuer,
        redirectUrl: window.location.origin,
      });
    },
    [sessionLogin]
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    const idp = e.target.value ?? inputIdp;
    login(idp);
  };

  const handleDefaultLogin = async (e) => {
    e.preventDefault();
    const oidcIssuer = await getOidcIssuer(inputWebId);
    if (oidcIssuer) {
      login(oidcIssuer);
    } else {
      const idp = deriveIdpFromWebId(inputWebId);
      login(idp);
    }
  };

  const handleInputChange = (e) => {
    setInputWebId(e.target.value);
  };

  const handleIdpInputChange = (e) => {
    setInputIdp(e.target.value);
  };

  return (
    <Container fluid className="login-container">
      <Row>
        <Col>
          <h3>Log in with your WebID</h3>
          <form onSubmit={handleDefaultLogin}>
            <input
              type="text"
              value={inputWebId}
              onChange={handleInputChange}
            />
            <Button className="primary-button">Sign In</Button>
          </form>
          <h3 className="section-title">Or log in with your provider</h3>
          <ul className="idp-list">
            <li>
              <Button
                className="primary-button"
                value="https://solidcommunity.net"
                onClick={handleLogin}
              >
                Log in with solidcommunity.net
                <img width="20px" src={solid_icon}></img>
              </Button>
            </li>
            <li>
              <Button
                className="primary-button"
                value="https://inrupt.net"
                onClick={handleLogin}
              >
                Log in with inrupt.net
                <img width="20px" src={solid_icon}></img>
              </Button>
            </li>
            <li>
              <Button
                className="primary-button"
                value="https://login.inrupt.com"
                onClick={handleLogin}
              >
                Log in with Inrupt PodSpaces
                <img width="20px" src={podspaces_logo}></img>
              </Button>
            </li>
            <li>
              <Button className="primary-button" onClick={showIdpModal}>
                Log in with another provider
              </Button>
              <Modal show={isIdpModalOpen} onHide={hideIdpModal}>
                <Modal.Header>
                  <Modal.Title>Log In With Another Provider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleLogin}>
                    <input
                      type="text"
                      value={inputIdp}
                      onChange={handleIdpInputChange}
                    />
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={hideIdpModal}>Cancel</Button>
                  <Button className="primary-button">Sign In</Button>
                </Modal.Footer>
              </Modal>
            </li>
          </ul>
          <h3 className="section-title">Don't have a WebID?</h3>
          <ul className="idp-list">
            <li>
              <Button className="primary-button">
                <a
                  className="btn btn-primary primary-button"
                  href="https://solidcommunity.net/register"
                >
                  Sign up with solidcommunity.net
                </a>
                <img width="20px" src={solid_icon}></img>
              </Button>
            </li>
            <li>
              <Button className="primary-button">
                <a
                  className="btn btn-primary primary-button"
                  href="https://inrupt.net/register"
                >
                  Sign up with inrupt.net
                </a>
                <img width="20px" src={solid_icon}></img>
              </Button>
            </li>
            <li>
              <Button className="primary-button">
                <a
                  className="btn btn-primary primary-button"
                  href="https://start.inrupt.com"
                >
                  Sign up with Inrupt PodSpaces
                </a>
                <img width="20px" src={podspaces_logo}></img>
              </Button>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
